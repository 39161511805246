
.jodit-workplace {
  min-width: 100vh !important;
}

.report__editor {
  max-width: 100%;
}
.report__editor h2 {
  text-align: center !important;
  width: fit-content;
}

.report__editor tr td input {
  opacity: 0.5;
  pointer-events: none;
}
.viewer {
  border: 1px solid rgb(177, 177, 177);
  max-width: 100%;
  padding: 5rem 3rem;
  overflow: auto !important;
}

/* .jodit-react-container > textarea {
  display: none;
} */

.jodit-status-bar {
  display: none;
}

.jodit-wysiwyg {
  min-height: 50vh !important;
}

.hidden {
  display: none;
}

.disable {
  pointer-events: none !important;
}

head tr:first-of-type td:first-of-type input {
  opacity: 0.5;
  pointer-events: none;
}

.header table td {
  width: 100%;
  overflow: auto;
  height: auto;
}

.jodit-workplace {
  min-width: 100% !important;
  padding: 0 2rem;
  margin-top: 3rem !important;
}
.jodit-ui-group_line_true{
  position: fixed !important;
  z-index: 10 !important;
  background-color: #f5f5f6 !important;

}

.jodit-toolbar-collection_mode_vertical .jodit-ui-group, .jodit-toolbar-editor-collection_mode_vertical .jodit-ui-group{
  min-width: 8rem !important;
}

/* _______________________________Confirmation Modal______________________________ */
.modal__btns {
  display: flex;
  justify-content: end;
  /* align-items: center; */
  gap: 1.2rem;
}

.react-responsive-modal-modal{
  background-color: #0f1215;
}
.react-responsive-modal-closeButton svg{
  color: white !important  ;
}
/* _______________________________AI report ______________________________ */

#ai_report_container th,
#ai_report_container td {
  border: 1px solid rgb(156, 154, 154) !important;
  padding: 0.1rem 0.4rem;
}

@media screen and (max-width: 1200px) {
  .editor__container {
    display: grid;
    grid-template-columns: 92%;
    /* gap: 3rem; */
  }
}

.report_container {
  background-color: white;
  min-height: 100vh;
}
.appbar {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 0 3rem;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1ca2e0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.appbar h2 {
  color: white !important;
}
.back_btn {
  display: flex;
  gap: 0.5rem;
  cursor: pointer !important;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.button {
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.new_report_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.new_report_section button {
  margin-top: 2rem;
}
.action_btns {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}

.report__section {
  /* width: 50%; */
  overflow: auto;

  margin: auto;
  color: black !important;

  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}

.report_data {
  margin: 2rem 0;
}

.header {
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}
.organiztion__identity {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organiztion__identity img {
  height: auto;
  width: 8.5rem;
}

.organiztion__identity h1,
h2 {
  text-align: center;
  width: fit-content;
  color: rgb(32, 32, 32);
}

.organiztion__identity h1,
h2,
h3,
h4,
p {
  margin-bottom: 0;
}

.contact__info {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}
.hr {
  width: 1px;
  background-color: black !important;
}

.basic__info {
  display: flex !important;
  justify-content: space-between !important;
  align-items: right;
}

.patient__identity,
.report__dates {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.patient__identity p,
.report__dates p {
  text-align: right;
  width: fit-content;
  color: rgb(27, 27, 27);
  line-height: 0.1rem;
  font-size: 0.8rem;
}

.report_body {
  font-family: 'Roboto', sans-serif !important;
}

.report_body textarea {
  width: 100%;
  outline: none;
  border: none;
  font-size: 1rem;
  resize: none;
  margin: 2rem;
}
.report__footer {
  display: flex;
  justify-content: end;
  color: black;
  align-items: flex-end;
  background-color: rgb(28, 162, 224);
}
textarea::-webkit-scrollbar {
  width: 0px;
}

button {
  background-color: rgb(28, 162, 224);
  border: none;
  padding: 0.6rem 1rem;
  color: white;
}

.report_data p {
  text-align: left !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 0.2rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

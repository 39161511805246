table {
    border-collapse: collapse;
  }
  /* table tr td {
    border: 1px solid #bfccd4;
  } */
  
  td tr {
     padding: 6.4px;
  }
  
  th {
     border-collapse: collapse;
     /* border: 1px solid #bfccd4; */
  
  }
  